import { defineStore } from 'pinia';
const useScriptStore = defineStore('script', {
    state: () => {
        return {
            callVarFun: [],
            userinfo: {},
            notebookId: '',
            showIndex: false,
            runCodeDone: [],
            variableList: [],
            chartData: {},
            userSetStatus: false,
            userPricesStatus: false
        };
    },
    getters: {},
    actions: {},
    persist: {
        storage: sessionStorage,
        paths: ['callVarFun', 'userinfo', 'notebookId', 'showIndex', 'runCodeDone', 'variableList', 'chartData']
    },
});
export default useScriptStore;
