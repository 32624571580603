import instance from '@/api/request';
export const getUsers = () => instance.request({
    url: `comfortuni/gugu/access/users`,
    method: 'GET',
    needToken: true,
});
export const getTargets = () => instance.request({
    url: `comfortuni/gugu/access/targets`,
    method: 'GET',
    needToken: true,
});
export const getRoles = () => instance.request({
    url: `comfortuni/gugu/access/roles`,
    method: 'GET',
    needToken: true,
});
export const rolesAdd = (params) => instance.request({
    url: `comfortuni/gugu/access/roles/add`,
    method: 'POST',
    data: params,
    needToken: true,
});
export const roleDelete = (params) => instance.request({
    url: `comfortuni/gugu/access/roles/delete?role=${params.user}`,
    method: 'DELETE',
    data: params,
    needToken: true,
});
export const permissionPost = (params) => instance.request({
    url: `comfortuni/gugu/access/roles/permission`,
    method: 'POST',
    data: params,
    needToken: true,
});
export const permissionDelete = (params) => instance.request({
    url: `comfortuni/gugu/access/roles/permission`,
    method: 'DELETE',
    data: params,
    needToken: true,
});
export const associatedPost = (params) => instance.request({
    url: `comfortuni/gugu/access/roles/associated`,
    method: 'POST',
    data: params,
    needToken: true,
});
export const adminaction = (params) => instance.request({
    url: `comfortuni/gugu/adminaction`,
    method: 'POST',
    data: params,
    needToken: true,
});
