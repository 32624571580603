import { ElMessage } from 'element-plus';
export function getFileName(filePath) {
    // 获取文件扩展名的位置
    const dotIndex = filePath.lastIndexOf('.');
    // 获取文件名（不带扩展名）
    const fileNameWithoutExtension = filePath.substring(0, dotIndex);
    return {
        fileName: fileNameWithoutExtension,
        extension: filePath.substring(dotIndex + 1),
    };
}
export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export const exportPyCode = (cells) => {
    return JSON.stringify({
        cells: cells,
        metadata: {
            kernelspec: {
                display_name: 'Python 3 (ipykernel)',
                language: 'python',
                name: 'python3',
            },
            language_info: {
                codemirror_mode: {
                    name: 'ipython',
                    version: 3,
                },
                file_extension: '.py',
                mimetype: 'text/x-python',
                name: 'python',
                nbconvert_exporter: 'python',
                pygments_lexer: 'ipython3',
                version: '3.12.3',
            },
        },
        nbformat: 4,
        nbformat_minor: 5,
    });
};
export const formatTimestamp = (timestamp) => {
    // 创建一个新的 Date 对象
    const date = new Date(timestamp);
    // 提取年、月、日、小时、分钟和秒
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要 +1
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const second = ('0' + date.getSeconds()).slice(-2);
    // 返回格式化的字符串
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};
export function combineArrays(nameArray, versonArray) {
    const result = [];
    for (let i = 0; i < nameArray.length; i++) {
        result.push({
            name: nameArray[i],
            version: versonArray[i],
        });
    }
    return result;
}
// 数组切片
export function splitArray(array, pageSize) {
    let result = [];
    let tempArray = [];
    for (let i = 0; i < array.length; i++) {
        tempArray.push(array[i]);
        // 当临时数组达到最大长度或已是原数组的最后一个元素时，将其添加到结果数组并重置临时数组
        if (tempArray.length === pageSize || i === array.length - 1) {
            result.push(tempArray);
            tempArray = [];
        }
    }
    return result;
}
export function transformObjectArray(array) {
    const grouped = array.reduce((acc, item, index) => {
        if (!acc[item.name]) {
            acc[item.name] = {
                id: index,
                name: item.name,
                children: [],
            };
        }
        acc[item.name].children.push({
            name: item.action_info.name,
            target: item.target,
            id: item.id,
        });
        return acc;
    }, {});
    return Object.values(grouped);
}
export function flattenArray(arr) {
    let flatArr = [];
    for (let item of arr) {
        flatArr.push(item);
        if (item.children) {
            flatArr = flatArr.concat(flattenArray(item.children));
            // delete item.children;
        }
    }
    return flatArr;
}
export const transformObject = (obj) => {
    const keys = Object.keys(obj);
    if (keys.length) {
        let returnArr = [];
        for (let key in obj) {
            obj[key][0].forEach((item) => {
                returnArr.push({ ...item, cellName: key });
            });
        }
        return returnArr;
    }
    else {
        return [];
    }
};
export const mergeMessages = (array) => {
    let resultText = '';
    for (let i = 0; i < array.length; i++) {
        if (array[i].content !== null) {
            resultText += array[i].content.toString() + '\n';
        }
    }
    return [
        {
            output_type: 'stream',
            name: 'stdout',
            text: resultText,
        },
    ];
};
export const copyToClipboard = (text) => {
    if (navigator.clipboard) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
            ElMessage({
                message: '复制成功',
                type: 'success',
            });
        })
            .catch(() => {
            ElMessage({
                message: '复制失败',
                type: 'warning',
            });
        });
    }
    else {
        // 对于不支持 navigator.clipboard 的浏览器，可以使用其他方法，如创建一个临时的 textarea 并模拟复制操作
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        ElMessage({
            message: '不支持',
            type: 'warning',
        });
    }
};
export const strToArr = (str) => {
    // const str = '  age   name   id\n0   23   一周   1\n1   24   一周2   2\n';
    // 清理字符串并分割成数组
    const rows = str.split('\n');
    console.log(rows);
    let arrs = [];
    rows.forEach((item) => {
        arrs.push(item.split(/\s+/));
    });
    const keys = arrs[0];
    const values = arrs.slice(1, arrs.length - 1);
    let objs = [];
    values.forEach((item, _index) => {
        let obj = {};
        item.forEach((child_item, child_index) => {
            if (child_index > 0) {
                obj[keys[child_index]] = child_item;
            }
        });
        objs.push(obj);
    });
    console.log(objs);
    return {
        keyList: keys.slice(1),
        data: objs
    };
};
