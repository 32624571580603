import instance from '@/api/request';
export const userLogin = (payload) => {
    return instance.request({
        url: 'comfortuni/gugu/login',
        method: 'POST',
        data: payload,
        needToken: false,
    });
};
export const userRegister = (payload) => instance.request({
    url: `comfortuni/gugu/register`,
    method: 'POST',
    data: payload,
    needToken: true,
});
export const userMail = (payload) => instance.request({
    url: `comfortuni/gugu/resetuser/mail`,
    method: 'POST',
    data: payload,
    needToken: true,
});
export const updatePassword = (payload) => instance.request({
    url: `comfortuni/gugu/resetuser/updatepassword`,
    method: 'POST',
    data: payload,
    needToken: true,
});
export const verifycode = () => instance.request({
    url: `comfortuni/gugu/verifycode`,
    method: 'GET',
    needToken: true,
});
export const updateinfo = (params) => instance.request({
    url: `comfortuni/gugu/update/updateinfo`,
    method: 'POST',
    data: params,
    needToken: true,
});
